<template>
  <div>
    <a-row>
      <a-col :span="8">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'">
            Usuário
          </label>
          <a-select
            class="travel-input"
            show-search
            placeholder="Selecione"
            :style="'width: 100%'"
            v-decorator="[
              'user_id',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of userList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    tempCustomer: Object,
    customer: Object,
    customerForm: Object,
  },
  data() {
    return {
      userList: [],
    };
  },
  mounted() {
    this.$http.get("/user/list?page=1&per_page=300").then(({ data }) => {
      this.userList = data.data.map((user) => {
        return {
          label: `${user.id} - ${user.first_name} ${user.last_name}`,
          value: user.id,
        };
      });
    });

    this.customerForm.setFieldsValue({
      user_id: this.tempCustomer.user.id,
    });
  },
  methods: {},
};
</script>

<style lang="sass" scoped></style>
