var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("\n          Usuário\n        ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'user_id',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'user_id',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",style:('width: 100%'),attrs:{"show-search":"","placeholder":"Selecione"}},_vm._l((_vm.userList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n            "+_vm._s(item.label)+"\n          ")])}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }